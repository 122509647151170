import md5 from 'blueimp-md5';
import {
  ElMessage,
} from 'element-plus';
import QueryAuthClass from '../../../store/class/queryAuthClass';
import myAxios from '../../../utils/axios/index_v1';

export default class UPdatePwdClass extends QueryAuthClass {
  constructor(updatepwdcompsRefs, updatePwdDatas, activeDatas, storeDatas, setFilterOfContainer, router) {
    super(storeDatas, setFilterOfContainer, router);
    this.updatepwdcompsRefs = updatepwdcompsRefs;
    this.updatePwdDatas = updatePwdDatas;
    this.activeDatas = activeDatas;
  }

  // verifyForm 验证表单是否正确
  // @returns promise :true:不通过 ,false:验证通过
  verifyForm = async () => {
    let b = false;
    await this.updatepwdcompsRefs.value.validate((err) => {
      if (!err) {
        b = true;
      }
    });
    if (b) {
      ElMessage.error('填写密码有误,请核对!');
      return b;
    }
    if (this.updatePwdDatas.confmPassWord !== this.updatePwdDatas.password) {
      this.activeDatas.errConfmPassWord = '两次密码不一致';
      ElMessage.error('填写密码有误,请核对!');
      return true;
    }
    this.activeDatas.errConfmPassWord = '';
    return false;
  }

  // submitFormDatas 提交表单数据至服务器修改
  submitFormDatas() {
    myAxios().initAxios({
      method: 'PUT',
      url: '/update_pwd',
      params: { newpwd: md5(this.updatePwdDatas.password) },
    }).then((res) => {
      // 成功修改后必须退出，再重新登陆，cookie才会更新
      if (res.code === 200) {
        if (this.setFilterOfContainer.isLogin) {
          this.logOutFunc(`${res.message},请重新登陆！`);// 退出登陆
          // 展示登陆框
          this.setFilterOfContainer.setFilter(0.5);
          this.setFilterOfContainer.loginRunLoadFunc(); // 登陆将显示登陆弹窗
        }
      }
    }).catch((err) => {
      ElMessage.log('操作失败');
      ElMessage({
        type: 'error',
        message: err.message,
        duration: 3000,
      });
    });
  }
}
