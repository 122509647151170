<template>
  <div class="updatepwdcomps">
    <el-form
      :model="updatePwdDatas"
      :rules="updatePwdRules"
      ref="updatepwdcompsRefs"
      label-width="120px"
      class="updatepwd"
      @submit.prevent
    >
      <el-form-item label-width="120px">
        <p>
          您正在对账号
          <el-link type="primary">{{setFilterOfContainer.userNickname}}</el-link>进行修改密码操作，请核对账号无误后，再进行如下操作。
        </p>
      </el-form-item>
      <el-form-item prop="password" label="新密码" :error="activeDatas.errPassword">
        <el-input
          size="medium"
          show-password
          clearable
          placeholder="请输入新密码"
          v-model="updatePwdDatas.password"
        ></el-input>
      </el-form-item>
      <el-form-item prop="confmPassWord" label="确定密码" :error="activeDatas.errConfmPassWord">
        <el-input
          size="medium"
          show-password
          clearable
          placeholder="请再次输入新密码"
          v-model="updatePwdDatas.confmPassWord"
        ></el-input>
      </el-form-item>
      <el-form-item label-width="120px">
        <div class="btnDiv">
          <el-button type="primary" @click="submitFormFun" native-type="submit">确认</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { inject, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
// import {
//   ElMessage,
// } from 'element-plus';
import Rules from '../../utils/utilsClass/rulesClass';
import UpdatePwdClass from './class/updatePwdClass';

export default {
  name: 'updatepwdcomps',
  components: {},
  setup() {
    const updatepwdcompsRefs = ref();
    const router = useRouter();
    const storeDatas = inject('storeDatas');
    const setFilterOfContainer = inject('setFilterOfContainer');
    // 输入框值
    const updatePwdDatas = reactive({
      password: '',
      confmPassWord: '',
    });

    // 验证器
    const updatePwdRules = reactive({
      password: [Rules.PassWordRules],
      confmPassWord: [Rules.PassWordRules],
    });

    // 动态展示数据
    const activeDatas = reactive({
      errPassword: '', // 手动错误信息
      errConfmPassWord: '', // 手动错误信息
    });

    const updatePwdClass = new UpdatePwdClass(updatepwdcompsRefs, updatePwdDatas, activeDatas, storeDatas, setFilterOfContainer, router);
    function submitFormFun() {
      try {
        updatePwdClass.verifyForm().then((isError) => {
          // true:不通过 ,false:验证通过
          if (!isError) {
            // 提交修改密码
            updatePwdClass.submitFormDatas();
          }
        });
      } catch (error) {
        console.error(error);
      }
    }
    return {
      updatepwdcompsRefs,
      updatePwdDatas,
      updatePwdRules,
      activeDatas,
      storeDatas,
      setFilterOfContainer,
      router,
      submitFormFun,
    };
  },
};
</script>
<style lang="scss" scoped>
.updatepwdcomps {
	display: flex;
	justify-content: left;
	.updatepwd {
		width: 70%;
		padding: 20px 0;
		:deep(.el-button) {
			width: 100%;
		}
		p {
			// margin: 10px 0 20px 40px;
			color: $buttomcolor;
			font-size: 14px;
			min-width: 430px;
			line-height: 20px;
		}
	}
}
</style>
